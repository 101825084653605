import {atom} from 'recoil';

export const info = atom({
    key: 'info',
    default: {
        'name': 'Duy Anh',
        'contactSocialNetwork': 'Facebook',
        'socialNetwork': {
            'LinkedIn': 'https://www.linkedin.com/in/duyanh14',
            'Facebook': 'https://www.facebook.com/duyanhn14',
            'Github': 'https://github.com/duyanh14',
            // 'Skype': 'https://join.skype.com/invite/apElmE4Maolj'
        }
    },
});
